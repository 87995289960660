



































































































import {
  SfBar, SfButton, SfBreadcrumbs, SfImage, SfList, SfMenuItem
} from '~/components';

import {
  defineComponent,
  useContext,
  useRouter,
  useRoute,
  computed,
  useFetch,
  ref
} from '@nuxtjs/composition-api';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';
import { useUser } from '../../composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';
import { useConfig } from '../../../../composables/useConfig';
import { useMagentoConfiguration } from '../../../../composables/useMagentoConfiguration';

export default defineComponent({
  name: 'MyAccount',
  components: {
    SfBar,
    SfButton,
    SfBreadcrumbs,
    SfImage,
    SfList,
    SfMenuItem,
  },
  head() {
    return Object.assign({},
      { 'title': `Account - ${this.title}` }
    );
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { i18n, localeRoute, localePath } = useContext();
    const { user, load: loadUser, isAuthenticated } = useUser();
    const { config } = useConfig();
    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const { sidebarLinkGroups, logoutUser } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const isOnAccountPage = computed(() => route.value.matched.length === 1);
    const isOnLoginRegisterPage = computed(() => route.value.path.includes('login-register'));
    const goHomeRoute = () => router.push(localeRoute({ name: 'home' }));
    const title = computed(() => i18n.t(route.value.matched.at(-1)?.meta.titleLabel as string));

    /**
     * #tab-id: handler-name
     */
    const handlers = {
      'log-out': logoutUser,
    };

    const getHandler = (id: string) => handlers[id] ?? {};

    const breadcrumbs = ref([]);
    breadcrumbs.value = [
      {
        link: localePath(computed(() => isOnAccountPage.value ? '/home' : '/customer').value),
        text: '',
      },
      {
        link: '#',
        text: title.value
      }
    ];

    const userFirstName = computed(() => user.value
      ? user.value.firstname
      : userGetters.getFirstName(user.value));

    const currentCurrencySymbol = computed(() => {
      try {
        return (0).toLocaleString(
          selectedLocale.value.replace(/[!"$-/:-?[\]^_`{-~]/, '-'),
          {
            style: 'currency',
            currency: selectedCurrency.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        )
          .replace(/\d/g, '')
          .trim();
      } catch {
        return selectedLocale.value;
      }
    });

    return {
      sidebarLinkGroups,
      title,
      isOnSubpage,
      goHomeRoute,
      logoutUser,
      isAuthenticated,
      getHandler,
      breadcrumbs,
      userFirstName,
      currentCurrencySymbol,
      storeConfig: config,
      isOnAccountPage,
      isOnLoginRegisterPage
    };
  },
});
