import type { RawLocation } from 'vue-router';
import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';

type LinkGroup = { title: string, showOnlyForAuthenticated?: boolean, items: LinkGroupItem[] };
type LinkGroupItem = {
  id: string,
  label: string,
  link?: RawLocation,
  showOnlyForAuthenticated?: boolean,
  showOnlyForNotAuthenticated?: boolean,
  onlyForMobile?: boolean
  onlyForDesktop?: boolean
};

export const useSidebarLinkGroups = () => {
  const { localeRoute } = useContext();
  const { logout } = useUser();
  const router = useRouter();

  const sidebarLinkGroups : LinkGroup[] = [
    {
      title: 'Personal details',
      showOnlyForAuthenticated: false,
      items: [
        {
          id: 'login-register',
          label: 'Log in/Register',
          link: { name: 'customer-login-register' },
          showOnlyForAuthenticated: false,
          showOnlyForNotAuthenticated: true,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'my-profile',
          label: 'My profile',
          link: { name: 'customer-my-profile' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'address-details',
          label: 'Addresses details',
          link: { name: 'customer-addresses-details' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'my-wishlist',
          label: 'Wishlist',
          link: { name: 'customer-my-wishlist' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        // {
        //   id: 'rewards-status',
        //   label: 'My Togas Rewards status',
        //   link: { name: 'customer-my-rewards-status' },
        //   showOnlyForAuthenticated: true,
        //   showOnlyForNotAuthenticated: false,
        //   onlyForMobile: false,
        //   onlyForDesktop: false,
        // },
        {
          id: 'my-newsletter',
          label: 'Newsletter',
          link: { name: 'customer-my-newsletter' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'shipping-location',
          label: 'Shipping Location',
          link: { name: 'customer-shipping-location' },
          showOnlyForAuthenticated: false,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: true,
          onlyForDesktop: false,
        },
        {
          id: 'language',
          label: 'Language',
          link: { name: 'customer-language' },
          showOnlyForAuthenticated: false,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: true,
          onlyForDesktop: false,
        },
        {
          id: 'contact-us',
          label: 'Contact Us',
          link: { name: 'contact-us' },
          showOnlyForAuthenticated: false,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: true,
          onlyForDesktop: false,
        },
        {
          id: 'loyalty-program',
          label: 'Loyalty Program',
          link: { name: 'customer-care-loyalty-program' },
          showOnlyForAuthenticated: false,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: true,
          onlyForDesktop: false,
        },
      ],
    },
    {
      title: 'Order details',
      showOnlyForAuthenticated: true,
      items: [
        {
          id: 'order-history',
          label: 'Order history',
          link: { name: 'customer-order-history' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'my-reviews',
          label: 'My reviews',
          link: { name: 'customer-my-reviews' },
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
        {
          id: 'log-out',
          label: 'Log out',
          showOnlyForAuthenticated: true,
          showOnlyForNotAuthenticated: false,
          onlyForMobile: false,
          onlyForDesktop: false,
        },
      ],
    },
  ];

  const logoutUser = async () => {
    await logout({});
    await router.push(localeRoute({ name: 'home' }));
  };

  return { sidebarLinkGroups, logoutUser };
};
